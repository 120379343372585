<template>
    <div class="relative pb-10">
      <div>
        <div class="relative">
          <div class="absolute inset-0">
            <!-- <video id="bg-vid" autoplay loop muted playsinline>
              <source src="/video/home-bg.mp4" type="video/mp4" />
            </video> -->
            <img class="w-full h-full object-cover" src="https://pslive.blob.core.windows.net/images/event/81be38c0-b197-11ef-8575-bf85d8bfee0f-event.jpg" alt="" />
            <div class="absolute inset-0" aria-hidden="true" style="background-image: linear-gradient(to left bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))" />
          </div>
          <div class="relative max-w-7xl mx-auto md:p-6">
            <div class="max-w-3xl mx-auto">
              <div>
                <img src="https://pslive.blob.core.windows.net/images/event/81be38c0-b197-11ef-8575-bf85d8bfee0f-event.jpg" alt="" class="md:rounded-xl">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative z-50 flex-none border-b py-2">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex flex-wrap items-center justify-center lg:flex-nowrap">
          <div class="flex space-x-2 md:space-x-4">
            <a @click.prevent="scrollToId('info-panel')" class="inline-flex justify-center rounded-xl bg-transparent p-2 md:p-4 text-sm md:text-base font-semibold text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500" href="#">
              Info
            </a>
            <a @click.prevent="scrollToId('faqs-panel')" class="inline-flex justify-center rounded-xl bg-transparent p-2 md:p-4 text-sm md:text-base font-semibold text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500" href="#">
              FAQs
            </a>
            <router-link to="/checkout/event/117/options" class="inline-flex justify-center rounded-xl bg-blue p-2 md:p-4 text-sm md:text-base font-semibold text-white hover:bg-blue-dark focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500" href="#">
              <span class="hidden md:inline">Get your tickets</span>
              <span class="md:hidden">Get tickets</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" id="info-panel">
        <!-- <img class="mb-20 rounded-xl" src="/img/prayer-storm-conference-2025-1920x1080.jpg" /> -->

        <h1 class="mt-10 lg:mt-15 font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-6xl">
          Nazarite School</h1>
        <div class="mt-6 space-y-6 font-display text-2xl tracking-tight text-gray-600">
          <p>Join Rob Sharp and the Prayer Storm Team for this year's Nazarite School. For four Friday evenings in February 2025, we will pursue Jesus as a unified community together.</p>
        </div>
        <dl class="mt-10 grid md:grid-cols-2 gap-x-10 gap-y-4 md:auto-cols-auto md:grid-flow-col md:grid-cols-none md:justify-start text-left">
          <div>
            <dt class="font-mono text-sm text-gray-600">When</dt>
            <dd class="mt-0.5 text-2xl font-semibold tracking-tight text-gray-900">7-28 August, 2025</dd>
          </div>
          <div>
            <dt class="font-mono text-sm text-gray-600">Venue</dt>
            <dd class="mt-0.5 text-2xl font-semibold tracking-tight text-gray-900">Bethany Church, 100 Church Rd, Stockport, SK8 4NQ
            </dd>
          </div>
        </dl>
      </div>
    </div>
    <section id="schedule" aria-labelledby="schedule-title" class="py-10">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 class="font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-5xl">
          Schedule
        </h2>
        <p class="mt-4 font-display text-2xl tracking-tight text-gray-600">
          Four friday evenings pursuing Jesus as a unified community.
        </p>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 sm:gap-x-4 lg:gap-x-8">
          <section>
            <ol role="list" class="rounded-xl mt-10 space-y-4 bg-white/60 p-4 md:p-8 text-center shadow-xl backdrop-blur border">
              <li>
                <h3 class="text-xl font-semibold tracking-tight text-gray-900">
                  7 February 2025
                </h3>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Evening 1</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">20:00 - 00:30 UTC</p>
                <p class="mt-1 font-mono text-sm text-slate-500">Pre-prayer (19:30)</p>
              </li>
            </ol>
          </section>
          <section>
            <ol role="list" class="rounded-xl mt-10 space-y-4 bg-white/60 p-4 md:p-8 text-center shadow-xl backdrop-blur border">
              <li>
                <h3 class="text-xl font-semibold tracking-tight text-gray-900">
                  14 February 2025
                </h3>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Evening 2</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">20:00 - 00:30 UTC</p>
                <p class="mt-1 font-mono text-sm text-slate-500">Pre-prayer (19:30)</p>
              </li>
            </ol>
          </section>
          <section>
            <ol role="list" class="rounded-xl mt-10 space-y-4 bg-white/60 p-4 md:p-8 text-center shadow-xl backdrop-blur border">
              <li>
                <h3 class="text-xl font-semibold tracking-tight text-gray-900">
                  21 February 2025
                </h3>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Evening 3</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">20:00 - 00:30 UTC</p>
                <p class="mt-1 font-mono text-sm text-slate-500">Pre-prayer (19:30)</p>
              </li>
            </ol>
          </section>
          <section>
            <ol role="list" class="rounded-xl mt-10 space-y-4 bg-white/60 p-4 md:p-8 text-center shadow-xl backdrop-blur border">
              <li>
                <h3 class="text-xl font-semibold tracking-tight text-gray-900">
                  28 February 2025
                </h3>
              </li>
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-gray-900">Evening 4</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">20:00 - 00:30 UTC</p>
                <p class="mt-1 font-mono text-sm text-slate-500">Pre-prayer (19:30)</p>
              </li>
            </ol>
          </section>
        </div>
      </div>
    </section>

    <section id="accomodation" aria-labelledby="accomodation-title" class="py-10">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 class="font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-5xl">
          What is the Nazarite School of Prayer?
        </h2>
        <p class="mt-4 font-display text-2xl tracking-tight text-gray-600">
          'Nazarite' means separated unto God. In the book of Numbers, God prepared the Israelites to go through the wilderness and take the promised land. This included the Nazarite vow: a process of preparation to fulfill Gods purposes.
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          Throughout history, Nazarites like Samson, Samuel, and Elijah appear when God is doing a fresh work. They are God's shock troops, operating behind enemy lines. Perhaps the most famous Nazarite, John the Baptist, articulated the call of all Nazarites as "Prepare the way for the Lord."
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          The 'Nazarite School of Prayer' is a six-week journey of separating and preparing ourselves to enter God's presence and fulfill His purposes. Based on the principles of Numbers 6, we fast things that distract us from God and pick up things that help us to focus on Him. Creating a modern wilderness of preparation.
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          If you want a breakthrough in your intimacy with God, if you desire to be part of a fresh move of His Spirit, join us on our Nazarite journey.
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          The core elements of the course include...
        </p>

        <p class="mt-2 font-display text-lg text-gray-600">
          <strong>Nazarite Commitments:</strong> Each Nazarite will ask the Holy Spirit what He wants them to lay down and to pick up, during the school. This often includes putting down Social Media and T.V. while picking up the Bible and prayer. However, God might surprise you! It's not a religious ritual, so only do what He asks of you. More guidance will be given on the first Friday event.
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          <strong>Nazarite Event, Every Friday from 8pm - 12:30am (with pre-prayer starting at 7:30pm):</strong> Because we follow the leading of the Holy Spirit each week is different, but they usually include worship, prayer, teaching, communion, and time with your Nazarite prayer partners. The first meeting is Friday the 7th of February and the last 28th of February (a total of 4 Fridays over 3 weeks). Events are not live streamed and, to help us keep momentum, weekly attendance is required. However, please prioritise your local church commitments.
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          <strong>Nazarite Teaching:</strong> During these events, a weekly teaching will unpack the theology and practice of being a New Covenant Nazarite in a contemporary context.
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          <strong>Communion:</strong> The Nazarites grew their hair to remember their covenant with God. (Numbers 6:5 & 15:38) As communion is the New Testament version of this reminder, (Luke 22:19) we encourage people to take communion as regularly as possible.
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          <strong>Nazarite Prayer Partners:</strong> During the Friday events, we break into small, gender-specific groups to ask each other how our commitments are going and how God is working in our lives. Privacy in these groups is paramount and long-term friendships often emerge from them.
        </p>
        <p class="mt-2 font-display text-lg text-gray-600">
          <strong>Devotions:</strong> You can choose to receive a short, Nazarite-themed devotional to keep you focused and inspired. These are great to do with communion.
        </p>
      </div>
    </section>

    <ContentTestimonials></ContentTestimonials>

    <ContentFAQsNaz></ContentFAQsNaz>

</template>

<script setup>
import ContentFAQsNaz from '@/components/ContentFAQsNaz.vue'
import ContentTestimonials from '@/components/ContentTestimonials.vue'

const scrollToId = (elementId) => {
  setTimeout(() => {
    const scrollTo = document.getElementById(elementId)
    scrollTo.scrollIntoView({ behavior: 'smooth' })
  }, 200)
}
</script>
