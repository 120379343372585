<template>
<div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <h2 class="font-bold text-blue">Volunteer Interest Form</h2>
          <div class="block text-sm text-gray-700">
            If you are not automatically redirected please view the <a href="https://prayerstorm.churchsuite.com/forms/7d8eezug" class="text-blue hover:underline">FORM HERE</a>.
          </div>
    </div>
  </div>
</div>
</template>

<script setup>
window.location.href = 'https://prayerstorm.churchsuite.com/forms/7d8eezug'
</script>
