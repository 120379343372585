<template>
  <section id="faqs-panel" class="py-10">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <h2 class="font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-5xl">
        Frequently Asked Questions
      </h2>
      <dl class="mt-4 space-y-6 divide-y divide-gray-900/10 pb-10">
        <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
          <dt>
            <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900">
              <span class="text-base font-semibold">{{ faq.question }}</span>
              <span class="ml-6 flex items-center">
                <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
              </span>
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" class="mt-2 pr-12">
            <p class="text-base leading-7 text-gray-600" v-html="faq.answer"></p>
          </DisclosurePanel>
        </Disclosure>
      </dl>
    </div>
  </section>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'

const faqs = [
  {
    question: 'Can I join after it has started?',
    answer: 'The Nazarite School works best went completed as a whole due to the nature of the commitment and the earlier foundational sessions. It would be better to start the school next time give you greater application and context.'
  },
  {
    question: 'What kind of things should I give up?',
    answer: 'In short - whatever God tells you. However, it is worth reflecting on the things that distract you from seeking God. Your heart will instruct you! (1 jn 3:19) Don\'t wait for a booming voice to tell you what you already know you should stop doing. Please note, we take a spiritual interpretation of the vows, you will not be required to follow them literally, so you can have a hair cut. More guidance will be given on the first Friday.'
  },
  {
    question: 'What if I break my commitments?',
    answer: 'Get straight back up and keep pursuing God!  Proverbs says "The godly may trip seven times, but they will get up again", godliness is not in never falling but in always allowing God to pick you up.  The psalmist affirms this: "though he fall, he shall not be cast headlong, for the LORD upholds his hand." (Ps 37:24) However, be aware of the \'what the heck\' effect!  This is a psychological phenomenon when someone breaks a commitment then thinks \'what the heck,\' I might as well break them all. This is a classic lie of the enemy, it will only help him heap more shame on you. '
  },
  {
    question: 'How do you avoid it becoming religious works?',
    answer: 'Having a religious spirit is the Nazarite\'s biggest danger. The Hebrew word Nazarite means separated ones, but the Greek word for separated ones is Pharisee! Jesus\'s mortal enemies. This must be a massive warning for us. Whenever the enemy fails to pull you into sin, he will try to push you into pride. To avoid this, always ensure you go through this sacrificial process as a love response to Christ\'s ultimate sacrifice, never as a way of getting something you have already been given by His sacrifice. Additionally, only do what God has asked you to do, don\'t compare your progress or sacrifice with others, and be comfortable if your victories are kept private but your mistakes public. Further teaching on this will be provided.'
  },
  {
    question: 'Are Nazarites only part of the Old Covenant?',
    answer: 'Undoubtedly the process changes in the New Covenant, but we believe the spiritual principles behind it are eternal. How to be a New Covernant Nazarite will be unpacked in the teaching sessions.  Its noteworthy that a close reading of Acts shows that there were several Nazarites in the New Testament. Acts 18:18 shows Paul shaved his hair as part of a Nazarite vow, while Acts 21:24 shows there were other young men finishing their Nazarite vow the same time as Paul. Additionally, the first Christian historian, Eusebius, recorded that the apostle James (leader of the Jerusalem church and brother of Jesus) was a Nazarite. While the Jewish historian Josephus, recorded that King Agrippa\'s (Acts 26) niece was a Nazarite who would intercede for him. Any study of church history will show every move of God is preceded by those who seek him diligently in a similar process.'
  },
  {
    question: 'Why are the meetings so late?',
    answer: 'Midnight is a type of wilderness. It is quiet, dark, and has few distractions. Anyone who has prayed past midnight will knows its power. (Act 16:25) It also has the advantage of not clashing with local church events.'
  },
  {
    question: 'Can I skip a session?',
    answer: 'Each night is different and the 6 weeks are a journey we go on together. To help us keep the momentum we ask that you attend every one you possibly can, as your absence will affect the whole group and your prayer partners. However, we recognize not everything is under your control and if you can\'t make a session for a valid reason we will try to ensure you stay up to date with what is happening.  We also understand if you have to prioritise local church commitments.'
  },
  {
    question: 'What does it cost?',
    answer: 'The course is free. However, we will take up a free-will offering at the beginning and end of the course to support and continue this unique ministry.'
  }
]
</script>
