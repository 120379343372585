<template>
  <section id="faqs-panel" class="py-10">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <h2 class="font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-5xl">
        Frequently Asked Questions
      </h2>
      <dl class="mt-4 space-y-6 divide-y divide-gray-900/10">
        <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
          <dt>
            <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900">
              <span class="text-base font-semibold">{{ faq.question }}</span>
              <span class="ml-6 flex items-center">
                <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
              </span>
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" class="mt-2 pr-12">
            <p class="text-base leading-7 text-gray-600" v-html="faq.answer"></p>
          </DisclosurePanel>
        </Disclosure>
      </dl>
    </div>
  </section>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'

const faqs = [
  {
    question: 'What is the Prayer Storm Conference?',
    answer: 'The Prayer Storm Conference is a gathering of people dedicated to prayer, worship, and spiritual revival. It is a time to come together in worship, experience powerful teaching, and be inspired to deepen your faith and connection with God.'
  },
  {
    question: 'How do I register for the conference?',
    answer: 'You can register directly on our website by clicking the "Register" button. Tickets are free of charge.'
  },
  {
    html: true,
    question: 'Are meals included in the ticket?',
    answer: 'Meals are not included, but we have some suggestions of hotels and retaurants available nearby. You can find them here: <a href="https://pslive.blob.core.windows.net/files/netherlands-accommodation-and-restaurants.pdf" target="_blank" rel="noopener noreferrer" class="text-blue underline font-bold">Hotel and Restaurant Suggestions</a>.'
  },
  {
    html: true,
    question: 'Is there accommodation available nearby?',
    answer: 'Yes, we have some suggestions of hotels and retaurants available nearby. You can find them here: <a href="https://pslive.blob.core.windows.net/files/netherlands-accommodation-and-restaurants.pdf" target="_blank" rel="noopener noreferrer" class="text-blue underline font-bold">Hotel and Restaurant Suggestions</a>.'
  },
  {
    html: true,
    question: 'How do I get to the venue from Spaklerweg Subway station?',
    answer: 'We have compiled some directions here: <a href="https://pslive.blob.core.windows.net/files/netherlands-directions.pdf" target="_blank" rel="noopener noreferrer" class="text-blue underline font-bold">Directions from Spaklerweg Subway Station</a>.’,'
  },
  {
    html: true,
    question: 'What should I bring to the conference?',
    answer: `We suggest bringing the following:
    <ul>
      <li>A Bible and notebook for personal reflection</li>
      <li>Comfortable clothing, as you may be seated or on your feet during worship sessions</li>
      <li>Personal snacks or a water bottle</li>
      <li>Any necessary medication or items you may need throughout the day</li>
    </ul>`
  },
  {
    question: 'Are there opportunities to volunteer at the conference?',
    answer: 'Absolutely! Volunteers are essential to making this event a success. If you are interested in volunteering, please visit the volunteering page and sign up. Please note that you will need to cover your own travel and accommodation costs.'
  }
]
</script>
